import { useTranslation } from 'react-i18next';

import '../../../styles.css';
import './Footer.css'

const Footer = () => {

  const { t } = useTranslation();

  return (
    <div id='footer'>
      <a href='https://www.exac.com/' className='copyright'>
        {t('copyright')}
      </a>
    </div>
  );
}

export default Footer;
