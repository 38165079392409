import Home from "../components/home/Home";
import NotFound from "../components/error-page/NotFound.js";

import i18n from 'i18next';

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useEffect } from "react";
import '../styles.css'

const Router = () => {

  useEffect(() => {
    // All language codes can be found here https://stackoverflow.com/questions/5580876/navigator-language-list-of-all-languages
    // If the language code is not supported by us (see supported languages in localization/locales/config.js) the site will fallback to english
    let languageCode = navigator.language.substring(0, 2);
    i18n.changeLanguage(languageCode);
  });

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;