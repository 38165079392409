import { useState } from 'react';
import { ReactComponent as Logo } from '../../assets/ExactechLogo.svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../../styles.css';
import NavigationBar from '../shared/navigation-bar/NavigationBar.js';
import Footer from '../shared/footer/Footer.js';
import BarLoader from "react-spinners/BarLoader";

const Home = () => {

  const [input, setInput] = useState('');
  const [outputText, setOutputText] = useState(``);
  const [result, setResult] = useState(-1);
  const [showingResult, setShowingResult] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const filterPosts = (event) => {
    event.preventDefault();

    if (input === '') {
      alert(t('must-submit-number'));
      return
    }

    setIsLoading(true)

    const preLeadingZeros = input.toUpperCase().trim().split(/ +/).join('');
    let serialNumber = preLeadingZeros;

    axios
      .get(`/api/${serialNumber}`)
      .then((res) => {
        if (res.data === '') {
          setResult(-1);
          setShowingResult(true);
          setOutputText(t('invalid-number', { serialNumber: serialNumber }));
        } else if (res.data?.RECALL === 'YES') {
          setResult(1);
          setShowingResult(true);
          setOutputText(t('affected', { serialNumber: serialNumber }));
        } else if (res.data?.RECALL === 'NO') {
          setResult(0);
          setShowingResult(true);
          setOutputText(t('unaffected', { serialNumber: serialNumber }));
        }
      })
      .catch(err => {
        alert(t('unexpected-error'));
      })
      .finally(() => {
        setIsLoading(false)
      })
  };

  const restoreSearch = (event) => {
    event.preventDefault();
    setShowingResult(false);
  }

  return (
    <div className="content">
      <NavigationBar />
      <h1 className="mainTitle">{t('home-title')}</h1>
      <div className={showingResult ? result === -1 ? "invalid" : result === 1 ? "positive" : "negative" : "middleBox"}>
        {
          isLoading &&
          <BarLoader
            color={"#ffffff"}
            loading={isLoading}
            // cssOverride={override}
            width={"100%"}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        }
        {
          !showingResult &&
          <div className="centeredContent">
            <h2 className="prompt">{t('prompt-title')}</h2>
            <p className="warning">{t('prompt-description')}</p>
            <div className="form">
              <input
                type="text"
                maxLength="10"
                style={{ fontSize: 18 }}
                value={input}
                onChange={e => {
                  setInput(e.target.value)
                }}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    filterPosts(event);
                  }
                }}
                id="header-search"
                className="searchBox"
                placeholder={t('input-number')}
              />
              <button
                type="submit"
                className="button"
                onClick={filterPosts}
                disabled={isLoading}
                style={{ opacity: isLoading ? 0.3 : 1 }}>
                {t('check')}
              </button>
            </div>
          </div>
        }
        {
          showingResult &&
          <div className="centeredContent">
            <div className="mainOutput">
              {outputText}
            </div>

            <div className='secondaryOutput'>
              {
                result === -1 &&
                <div>
                  <p>{t('need-help')}</p>
                </div>
              }
              {
                result === 1 &&
                <div>
                  <p style={{ 'color': 'black' }}>{t('please-visit')} <a style={{ 'color': 'black' }} href="https://www.exac.com/medical-professionals/recall-information">{t('psa-link')}</a>{t('for-more-info')}</p>
                  <p style={{ 'color': 'black' }}>{t('extra-zeros')}</p>
                </div>
              }
              {
                result === 0 &&
                <div>
                  <p>{t('all-good')}</p>
                  <p>{t('extra-zeros')}</p>
                </div>
              }
            </div>
            <div className="acknowledgeButton" style={result === 1 ? { 'color': 'black' } : { 'color': 'white' }} onClick={restoreSearch}>
              {t('check-another')}
            </div>
          </div>
        }
      </div>
      <Footer />
    </div>
  );
}

export default Home;
