import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../../../assets/ExactechLogo.svg';
import LanguageChanger from '../../../localization/components/LanguageChanger.js';
import { Link } from 'react-router-dom';
import '../../../styles.css';
import './NavigationBar.css'

const NavigationBar = () => {

  const { t } = useTranslation();

  return (
    <div id='navigation-bar'>
      <a id='logo-parent' href='https://www.exac.com/medical-professionals/product-safety-alert/'>
        <Logo id='logo' />
      </a>
      <LanguageChanger />
    </div>
  );
}

export default NavigationBar;
