import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../../assets/ExactechLogo.svg';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import './LanguageChanger.css'
import i18n from '../config';

const LanguageChanger = () => {

  const { t } = useTranslation();

  const [language, setLanguage] = useState(i18n.language);

  const flagToLanguage = [
    { label: '🇺🇸', value: 'en' },
    { label: '🇪🇸', value: 'es' },
    { label: '🇫🇷', value: 'fr' },
    { label: '🇮🇹', value: 'it' },
    { label: '🇩🇪', value: 'de' },
    { label: '🇯🇵', value: 'ja' }
  ];

  const updateLanguage = (language) => {
    i18n.changeLanguage(language.target.value);
    setLanguage(i18n.language);
  };

  return (
    <Dropdown
      options={flagToLanguage}
      value={language}
      onChange={updateLanguage}
    />
  );
}

const Dropdown = ({ value, options, onChange }) => {
  return (
    <select value={value} onChange={onChange}>
      {
        options.map((option) => (<option value={option.value}>{option.label}</option>))
      }
    </select>
  );
};

export default LanguageChanger;
