import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Router from './router/Router';
import './localization/config';

ReactDOM.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>,
  document.getElementById('app')
);